import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { foodSearchActions, foodSearchSelectors } from '@ducks/foodSearch';
import { foodstuffActions } from '@ducks/foodstuff';
import requestStatus from '@src/utils/helpers/requestStatus';
import FoodstuffBulkEdit, { ITEMS_TO_FETCH } from './FoodstuffBulkEdit';

const DEBOUNCE_TIME = 500;

const mapStateToProps = state => ({
  searchResultItems: state.foodSearch.foodSearchItems,
  searchInputValue: state.foodSearch.foodSearchInputValue,
  isSearchEmpty: foodSearchSelectors.isSearchEmpty(state.foodSearch),
  isSearchValueAboveThreshold: foodSearchSelectors.isSearchValueAboveThreshold(
    state.foodSearch
  ),
  bulkFoodstuffPending:
    state.foodstuff.bulkRequestStatus === requestStatus.pending,
  bulkFoodstuffItems: state.foodstuff.bulkFoodstuffItems
});

const mapDispatchToProps = dispatch => ({
  getSearchResult: debounce(({ searchString }) => {
    dispatch(
      foodSearchActions.getAllFoodstuffSearchResult({
        searchString,
        maxResult: ITEMS_TO_FETCH
      })
    );
  }, DEBOUNCE_TIME),
  clearSearchResult: () => {
    dispatch(foodSearchActions.clearSearchResult());
  },
  clearSearchInputValue: () => {
    dispatch(foodSearchActions.clearInputValue());
  },
  setSearchInputValue: value => {
    dispatch(foodSearchActions.setInputValue(value));
  },
  getBulkFoodstuffItems: foodstuffIds => {
    dispatch(foodstuffActions.getBulkFoodstuffItems(foodstuffIds));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodstuffBulkEdit);
